.cs-main-container {
  border: none !important;
}
.cs-chat-container {
  border: none !important;
}
.cs-main-container .cs-chat-container .cs-message__content,
.cs-message {
  background: none;
}

.cs-message__content {
  background: none !important;
  padding: 10px 0 18px 0 !important;
}

.cs-message__sender-name,
.cs-message__sent-time {
  display: block !important;
}

.cs-message-input__content-editor-wrapper,
.cs-message-input__content-editor,
.cs-message-input__content-editor-container {
  background: none !important;
  padding: 0 !important;
}

.cs-message-input__content-editor::before {
  color: rgb(163, 163, 163);
  font-family: "NewfoundationWhyte-Regular" !important;
  font-size: 15px;
  font-weight: 300;
}

.cs-message-input__content-editor {
  font-family: "NewfoundationWhyte-Regular" !important;
  font-size: 15px;
  font-weight: 300;
}

/* .cs-message-input__content-editor-wrapper {
  padding: 0.9em !important;
} */

.ps__thumb-y {
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.cs-message-input__content-editor {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cs-message-input {
  border-radius: 18px;
  background-color: transparent !important;
  width: 100%;
}

.cs-message-input__tools {
  order: 2;
  justify-content: center;
  flex-direction: column !important;
}

.cs-main-container .cs-message__avatar {
  justify-content: flex-start;
  padding-top: 9px;
}

.cs-button.cs-button--attachment,
.cs-button.cs-button--send {
  color: darkgrey;
}

.cs-message__sender-name,
.cs-message__sent-time {
  display: block !important;
}

.cs-message__content-wrapper {
  width: 100%;
}

.agent-username-highlight {
  padding: 2.5px 5px;
  border: 1px solid rgb(201, 201, 201);
  border-radius: 6px;
  font-family: Viafont !important;
  font-size: 10px !important;
  line-break: unset !important;
  vertical-align: middle;
  line-height: unset !important;
}

.bubble-chat {
  display: inline-block;
  background: #f4f4f4;
  padding: 20px;
  border-radius: 18px;
  overflow: hidden;
}

.ant-tabs-nav-list {
  border-radius: 18px;
  background: rgba(255, 255, 255, 60%);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}

@media (min-width: 991px) {
  .cs-message-list__scroll-wrapper {
    padding: 0 !important;
  }

  .ant-tabs .ant-tabs-content-holder {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 998;
  }

  .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs-nav {
    z-index: 999;
  }

  .ant-tabs {
    padding-top: 20px;
  }
}

@media (max-width: 991px) {
  .ant-tabs {
    padding: 0 20px 20px;
  }

  /* .ant-tabs-nav-list,
  .ant-tabs-nav-wrap {
    justify-content: center !important;
  } */
}

@media screen and (min-width: 991px) and (max-width: 1123px) {
  .profile-tab {
    padding-top: 140px !important;
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1123px) {
  .profile-tab {
    padding-top: 85px;
    padding-bottom: 20px;
  }
}

.cs-message-list > .cs-message-list__scroll-wrapper > .cs-message {
  max-width: 650px;
  margin: 0;
}

.cs-message__avatar {
  margin-right: 10px !important;
  width: 28px !important;
}

.cs-button--attachment > svg:first-child,
.cs-button--send > svg:first-child {
  display: none;
}

.cs-input-toolbox .cs-button {
  margin: 0 !important;
}

.cs-input-toolbox {
  height: 31px;
}

.rnc__notification-item {
  box-shadow: none !important;
  justify-content: center;
}

.rnc__notification {
  max-height: 83px !important;
}

@media screen and (max-width: 400px) {
  .rnc__notification-item {
    padding: 0 20px;
  }
}

.ant-dropdown-trigger > .item-actions-wrapper > .anticon,
.item-actions-wrapper > .anticon {
  color: lightgrey !important;
}

.padding-normal {
  padding: 20px;
}

.padding-normal-vertical {
  padding-top: 20px;
  padding-bottom: 20px;
}

.ant-collapse {
  width: 240px;
  border-radius: 18px !important;
  border-color: #ebebeb;
}

.ant-collapse-content-box {
  padding: 0 16px 16px !important;
}

.ant-collapse-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center !important;
  padding: 15px !important;
  font-family: "NewfoundationWhyte-Regular";
  font-weight: 700;
  font-size: 12px;
  gap: 20px;
}

.ant-collapse-header,
.ant-collapse-item {
  border-radius: 18px !important;
  background-color: #ffff !important;
  border-color: #ebebeb !important;
}

.ant-collapse-content {
  border-top: none !important;
  border-radius: 18px !important;
  background-color: #ffff;
}

.ant-drawer-mask {
  background-color: transparent !important;
}

.ant-collapse-expand-icon {
  padding-inline-end: 0 !important;
}

.ant-collapse-header-text {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contributors-action-menu {
  color: #000;
  font-family: "NewfoundationWhyte-Regular";
  font-size: 11.5px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0.5px;
  height: 28px !important;
  padding-left: 10px;
  align-items: center;
}

.contributors-action-menu:hover {
  border-radius: 12px;
  background: #f8f8f8;
}

.chat-history-group > .ant-menu-item-group-title {
  color: #000;
  font-family: "NewfoundationWhyte-Regular" !important;
  font-size: 11.5px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px; /* 156.522% */
  letter-spacing: 0.2px;
}

.chat-history-group > .ant-menu-item-group-title:first-of-type {
  padding: 0 9px !important;
}

.chat-history-group:not(:first-of-type) {
  padding-top: 25px;
}

.chat-history-group .ant-menu-item .ant-menu-title-content {
  color: #000;
  font-family: "NewfoundationWhyte-Regular";
  font-size: 11.5px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px; /* 156.522% */
  align-items: left;
  width: 100%;
}

.chat-history-item > div,
.chat-history-item > span {
  padding: 9px !important;
}
.chat-history-item:hover > div,
.chat-history-item:hover > span {
  /* font-family: "NewfoundationWhyte-Bold" !important;
  font-weight: 700; */
  background-color: rgb(245, 245, 245);
  border-radius: 10px;
}

.chat-history-item:last-child {
  margin-bottom: 0 !important;
}

.folder-post-grid-item video {
  width: auto !important;
  height: auto !important;
  max-height: fit-content;
}

video {
  display: block;
  margin: auto;
  height: auto;
  max-height: 100%;
  width: 100%;
}

.ant-tabs .ant-tabs-tab {
  padding: 0;
  margin: 0 !important;
  border: 1px solid rgba(255, 255, 255, 0);
}

.ant-tabs-tab-active {
  border: 1px solid rgb(235, 235, 235) !important;
  border-radius: 18px;
  background-color: #ffff !important;
}

.ant-tabs-tab-btn:hover {
  background-color: rgba(245, 245, 245, 1) !important;
  border: 1px solid rgba(255, 255, 255, 0) !important;
}

.ant-tabs-tab-btn {
  border-radius: 18px;
  border: 1px solid rgba(255, 255, 255, 0);
  padding: 0 18px;
  height: 45px;
  text-transform: uppercase;
  font-size: 11.5px;
  letter-spacing: 0.5px;
  display: flex;
  flex-direction: column;
  text-shadow: none !important;
  justify-content: center;
  background-color: transparent;
  transition: none !important;
  box-shadow: none !important;
}

.ant-tabs-tab-active > .ant-tabs-tab-btn {
  height: 43px !important;
}

/* .ant-tabs-nav-list {
  flex-wrap: wrap;
  gap: 10px;
} */

.ant-tabs-nav-wrap {
  white-space: unset !important;
}

.ant-tabs-nav::before {
  content: unset !important;
  border: none;
}

.ant-tabs-ink-bar {
  display: none;
}
§ .cs-loader::after,
.cs-loader::before {
  content: none !important;
}

.cs-overlay {
  display: none !important;
}

/* FAQ Collapsible - override collapsible component style */
.FAQ-collapsible {
  .ant-collapse-header {
    flex-direction: row-reverse !important;
    padding: 12px 0 !important;
  }

  .ant-collapse-content-box {
    padding: 16px 0 !important;
  }

  .ant-collapse-header-text {
    padding-right: 20px !important;
    line-height: normal;
  }

  .ant-collapse-expand-icon {
    padding: 0 !important;
  }

  .ant-collapse {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .ant-collapse-item {
    border-bottom: 1px solid #ebebeb !important;
    border-radius: 0 !important;
    padding: 20px 0px !important;
  }

  .ant-collapse-item:first-of-type {
    border-top: 0.5px solid #ebebeb !important;
  }

  .ant-collapse-content {
    border-top: none !important;
  }

  .ant-collapse-item:last-child {
    border-radius: 0 !important;
  }
}

.suggested-contributor-user-chip-container:hover {
  background-color: rgb(245, 245, 245) !important;
  border-radius: 10px;
}

.suggested-contributor-user-chip-icon {
  display: none;
}

.suggested-contributor-user-chip-icon:hover {
  display: block;
  cursor: pointer;
}

.suggested-contributor-user-chip:hover + .suggested-contributor-user-chip-icon {
  display: block;
}

.view-selector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.view-selector-active svg,
.view-selector-active svg ellipse,
.view-selector-active svg circle,
.view-selector:hover svg,
.view-selector:hover svg ellipse,
.view-selector:hover svg circle {
  fill: black;
  transition: 0.5s ease;
}

.ant-list-item-meta {
  height: 40px;
  padding: 0 3px;
  border-radius: 8px;
  margin: 5px 0;
}

.ant-list-item-meta:hover {
  background-color: rgb(245, 245, 245);
}

.ant-list-item-meta-content {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.ant-list-item-meta-title,
.ant-list-item-meta-description {
  margin: 0 !important;
  font-size: 12px !important;
  line-height: 100% !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ant-list-item-meta-avatar {
  margin-inline-end: 0 !important;
}

.ant-list-items a:last-child .ant-list-item {
  border-block-end: none !important;
}

.ant-form-item-label {
  padding: 0 !important;
}

#editProfile > .ant-form-item .ant-form-item-control-input-content {
  flex: unset;
}
